import { Checkbox } from "components/ui";
import React, { useState } from "react";
import { IoSettings } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setstoredColumns } from "store/base/columns";

const CheckBoxDropdown = ({ columns}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch=useDispatch()
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleColumnToggle = (column) => {
    
    const updatedColumns = columns.map((col) =>
      col.value === column.value ? { ...col, visible: !col.visible } : col
    );
    dispatch(setstoredColumns(updatedColumns))
  
  };

  return (
    <div className="relative inline-block">
      <button
        className="p-2 bg-sky-100 flex items-center justify-center rounded-md focus:outline-none"
        onClick={toggleDropdown}
      >
        <IoSettings className="text-sky-500 text-[18px]" />
      </button>
      {dropdownOpen && (
        <div className="absolute left-1 mt-2 w-48 bg-white shadow-lg rounded-md p-3 z-50">
          {columns.map((column) => (
            <label key={column.id} className="flex items-center mb-2">
              <Checkbox
                checked={column.visible}
                onChange={(e) => handleColumnToggle(column)}
              />
              {/* <input
                type="checkbox"
                checked={column.visible}
                onChange={() => handleColumnToggle(column)}
                className="form-checkbox h-5 w-5 bg-[#99d2ed] border-gray-300 rounded "
              /> */}
              <span className="ml-2">{column.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckBoxDropdown;
