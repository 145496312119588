import React, { useState } from "react";
import FilterBar from "./filterBar";
import MotionFilters from "./motionFilters";
import { Button } from "components/ui";

const Header = ({
  search,
  setSearch,
  setFilterValue,
  filterValue,
  FilterMenu,
  handleBtnClick,
  btnLabel,
  placeholder,
  handleUploadBtn,
  uploadSongsBtnLabel,
  importExcelBtnLabel,
  handleImportExcel,
  initialColumns,
  title,
}) => {
  const [motionFilters, setMotionFilters] = useState(false);
  const onFilterClick = () => {
    setMotionFilters(!motionFilters);
  };

  return (
    <div>
      <FilterBar
        search={search}
        FilterMenu={FilterMenu}
        setSearch={setSearch}
        placeholder={placeholder}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        onFilterClick={onFilterClick}
        motionFilters={motionFilters}
        handleBtnClick={handleBtnClick}
        btnLabel={btnLabel}
        title={title}
        handleUploadBtn={handleUploadBtn}
        uploadSongsBtnLabel={uploadSongsBtnLabel}
        importExcelBtnLabel={importExcelBtnLabel}
        handleImportExcel={handleImportExcel}
        initialColumns={initialColumns}
      />
      <MotionFilters
        motionFilters={motionFilters}
        FilterMenu={FilterMenu}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />
    </div>
  );
};
export default Header;
