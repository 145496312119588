const appConfig = {
  apiBaseUrl: "https://api.vahan.pro",
  apiPrefix: "/api",
  authenticatedEntryPath: "app/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: true,
};

export default appConfig;
