import ApiService from "./ApiService";
import { APIS, USERS } from "constants/api.constant";

export async function apiSignIn(data, webName) {
  return ApiService.fetchData({
    url: webName === USERS.ADMIN ? APIS.AUTH.LOGIN : APIS.AUTH.VENDOR_LOGIN,
    method: "post",
    data: {
      ...data,
    },
  }).then((res) => res?.data);
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/reset-password",
    method: "post",
    data,
  });
}

// headers: {
//   'Content-Type': 'application/json',
//   'Access-Control-Allow-Origin': '*',
// },
