import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import {
  FaTruck,
  FaRegMoneyBillAlt,
  FaEye,
  FaUser,
  FaChartLine,
  FaPlay,
  FaCross,
} from "react-icons/fa";
import { IoIosInformationCircle, IoMdCopy } from "react-icons/io";
import {
  IoEarthSharp,
  IoStatsChart,
  IoBarChartSharp,
  IoFolderOpenOutline,
  IoFolder,
  IoMusicalNotes,
  IoPause,
} from "react-icons/io5";
import { TiThMenu, TiMap, TiSupport } from "react-icons/ti";
import { BiCategory } from "react-icons/bi";
import { MdOutlineFileUpload } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { RiAdvertisementFill } from "react-icons/ri";

export const USER_NAME_BY_USER_TYPE = [
  { label: "Super Admin", value: "admin" },
  { label: "Vendor", value: "vendor" },
];
export const MASTER_DATA_TYPES = [
  { label: "Super Admin", value: 1 },
  { label: "Vendor", value: 2 },
];

export const API_PAYLOAD_KEY = {
  MARKETTING_HEAD: "user-management-marketing-head",
  ZONAL_BUSINESS: "user-management-zonal-bussiness",
  STATE_BUSINESS: "user-management-state-bussiness",
  SEGMENT_HEAD: "user-management-segment-head",
  TERRITORY_MANAGER: "user-management-territory-manager",
  DEALER: "user-management-dealer",
  DEALER_MARKETING: "user-management-dealer-marketing",
  SALES_EXECUTIVE: "user-management-sales-executive",
};

export const USER_STATUS_OPTIONS = [
  { label: "Active", value: 1 },
  { label: "Deactive", value: 2 },
];
export const COLUMN_OPTIONS = [
  { label: "Name", value: "Name", visible: true },
  { label: "Email", value: "Email", visible: true },
  { label: "Phone Number", value: "Phone Number", visible: true },
  { label: "Wallet", value: "Wallet", visible: true },
  { label: "Price Per Request", value: "Price Per Request", visible: true },
  { label: "GST Number", value: "GST Number", visible: true },
  { label: "Address", value: "Address", visible: true },
];

export const HOME_SCREEN_VISIBILITY = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export const VEHICLE_CATEGORY = [
  { label: "GOODS CARRIER", value: 2 },
  { label: "PASSENGER CARRIER", value: 1 },
];

export const VEHICLE_TYPE = [
  { label: "BUS", value: "BUS" },
  { label: "TRUCK", value: "TRUCK" },
  { label: "COWL CHASSIS", value: "COWL CHASSIS" },
  { label: "CAB CHASSIS", value: "CAB CHASSIS" },
];

export const AC_TYPE = [
  { label: "AC", value: 0 },
  { label: "NON-AC", value: 1 },
];

export const ACTION_ICON_SIZE = ".875rem";

export const NAVIGATION_ICON = {
  ADVERTISEMENT: <RiAdvertisementFill />,
  CROSS_BUTTON: <ImCross />,
  PAUSE: <IoPause />,
  PLAY_ICON: <FaPlay />,
  COPY_ICON: <IoMdCopy />,
  SONG: <IoMusicalNotes />,
  FILE_UPLOAD: <MdOutlineFileUpload />,
  SONG_CATEGORY: <BiCategory />,
  MODAL: <FaTruck />,
  ZONES_STATES: <IoEarthSharp />,
  FINANCER: <FaRegMoneyBillAlt />,
  COMPITIORS: <FaEye />,
  APP_DEFAULTS: <TiThMenu />,
  GRAPH: <IoBarChartSharp />,
  MONTHLY_STAT: <IoStatsChart />,
  USER: <FaUser />,
  LEAD: <FaChartLine />,
  ENQUIRY: <IoIosInformationCircle />,
  VISIT_PURPOSE: <IoFolderOpenOutline />,
  CUSTOMER_CATEGORY: <IoFolder />,
  DEALER_SUMMARY: <TiSupport />,
  STATE_PROJECTION_SUMMARY: <TiMap />,
};

export const APP_NAME = "Vahan Pro";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const IMAGE_BASE_URL =
  "https://baduli-music.s3.ap-south-1.amazonaws.com/";
export const IMAGES = {
  FIREWORKS: "/img/festive/fireworks.gif",
  LOHRI: "/img/festive/lohri.gif",
};

export const TABLE_ACTION_KEYS = {
  EDIT: "Edit",
  DELETE: "Delete",
  ADD: "Add",
  VIEW: "View",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  NAVIGATE: "Navigate",
  DOWNLOAD: "Download",
  IMPORT: "Import",
  CHANGE_PASSWORD: "ChangePassword",
  STATUS: "status",
  VIEW_MORE_INFO: "viewMoreInfo",
  EXPORT: "Export",
  ACTIVITY_LOG: "activityLog",
  HOMESCREEN_VISIBLE: "homeScreenVisibility",
};

export const ACTIVE_INACTIVE_STATUS = [
  {
    label: "ACTIVE",
    identifier: 2,
    key: 1,
    value: true,
  },
  {
    label: "BLOCKED",
    identifier: 3,
    key: 2,
    value: false,
  },
];
export const HOMESCREEN_VISIBILITY_STATUS = [
  {
    label: "True",
    key: true,
    value: true,
  },
  {
    label: "False",
    key: true,
    value: false,
  },
];

export const TOAST_TIMING = 4000;

export const COMMON_DATE_FORMAT = "DD MMMM YYYY hh:mm A";

export const INITIAL_PAGE_COUNT = 10;

export const NO_DATA_FOUND = "-";

export const NAME_COLUMN_SIZE = "";

export const USER_MANAGEMNT_COMMON_SEARCH_INFO = "Search by name";

export const USER_MANAGEMNT_ACTION_CONSTANT = [
  { label: "Edit", key: TABLE_ACTION_KEYS.EDIT, toolTip: "Edit" },
];

export const LEAD_MODAL_ENQUIRED_CONSTANT = [
  {
    label: <FaEye style={{ fontSize: ACTION_ICON_SIZE }} />,
    key: TABLE_ACTION_KEYS.VIEW_MORE_INFO,
    toolTip: "Model Details",
  },
];

export const TOAST_MESSAGE = {
  USER_SAVED: "User saved successfully!",
  USER_UPADTE: "User updated successfully!",
};

export const PAGE_IDENTIFIER = {
  LISITING_LEADS: "lisiting-leads",
  LISITING_MARKETING_MANAGER: "lisiting-marketing-manager",
  LISITING_ZONAL_HEAD: "lisiting-zonal-head",
  LISITING_STATE_HEAD: "lisiting-state-head",
  LISITING_TERRITORY_HEAD: "lisiting-territory-head",
  LISITING_SEGMENT_HEAD: "lisiting-segement-head",
  LISITING_DEALER: "lisiting-dealer",
  DEALER_ORDER_REPORT: "dealer-order-report",
  STATE_PROJECTION_SUMMARY_REPORT: "state-projection-summary-report",
  LISITING_DEALER_MARKETING: "lisiting-dealer-marketing",
  LISITING_SALES_EXECUTIVE: "lisiting-sales-executive",
  LISITING_CONFIG_ADMIN: "lisiting-config-admin",
  LISITING_FINANCIER_SCHEME: "lisiting-financier-scheme",
  MASTER_DATA_MODELS: "master-data-models",
  MASTER_DATA_ZONES_AND_STATES: "master-data-zones-states",
  MASTER_DATA_FINANCE_SCHEME: "master-data-finance-scheme",
  MASTER_DATA_COMPETITORS: "master-data-competitors",
  MASTER_DATA_APP_DEFAULT: "master-data-app-default",
  MASTER_DATA_SOURCE_ENQUIRY: "master-data-source-enquiry",
  MASTER_DATA_CUSTOMER_CATEGORY: "master-data-customer-category",
};

export const FILTER_CONSTANT = {
  isSearch: {
    placeholder: USER_MANAGEMNT_COMMON_SEARCH_INFO,
    toolTipText: USER_MANAGEMNT_COMMON_SEARCH_INFO,
  },

  isCalendarFilter: {
    type: "date-range-picker",
    placeholder: "Select date range",
    filterKey1: "startDate",
    filterKey2: "endDate",
  },
  isResetButton: {
    label: "Reset",
    icon: <GrPowerReset />,
    filterKey: { statuses: "", startDate: null, endDate: null },
  },
  isFilterButton: {
    label: "Filter",
    icon: <CiFilter />,
    filters: [
      {
        component: "select",
        options: USER_STATUS_OPTIONS,
        className: "w-full sm:w-full mb-4 md:mb-4 w-40",
        placeholder: "Select status",
        filterKey: "statuses",
      },
    ],
  },
  isColumnButton: {
    label: "Column",
    // icon: <CiFilter />,
    columns: [
      {
        component: "select",
        options: COLUMN_OPTIONS,
        className: "sm:w-[160px] w-full  mb-4 md:mb-4 ",
        placeholder: "Select columns",
        filterKey: "columns",
      },
    ],
  },
};
