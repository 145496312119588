import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import statesDistricts from './statesDistrictsSlice'
import columns from './columns'

const reducer = combineReducers({
    common,
    statesDistricts,
    columns
})

export default reducer