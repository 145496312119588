import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStateDistrict } from "store/base/statesDistrictsSlice";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import {
  COLUMN_OPTIONS,
  REDIRECT_URL_KEY,
  TOAST_TIMING,
} from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { toast, Notification } from "components/ui";
import { setstoredColumns } from "store/base/columns";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values, webName) => {
    try {
      const resp = await apiSignIn(values, webName);

      const accessToken = resp?.data?.accessToken || "";
      const user = {
        ...resp?.data,
        userType: webName,
        authority: [webName],
      };

      dispatch(setUser(user));
      dispatch(onSignInSuccess({ accessToken }));

      toast.push(
        <Notification closable type="success" duration={TOAST_TIMING}>
          Login success
        </Notification>
      );

      return resp;
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);

      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(setStateDistrict({ states: [], districts: [], fetch: false }));
    dispatch(setstoredColumns(COLUMN_OPTIONS));

    localStorage.clear();
    document.title = "Vahan | Pro";
    navigate(appConfig.unAuthenticatedEntryPath);
    toast.push(
      <Notification closable type="success" duration={TOAST_TIMING}>
        Logout successfully
      </Notification>
    );
  };

  const signOut = async () => {
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
