export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const SML_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";

export const USERS = { ADMIN: "admin", VENDOR: "vendor" };
export const APIS = {
  AUTH: {
    LOGIN: "/admin/login",
    VENDOR_LOGIN: "/vendor/login",
  },

  VENDORS: {
    ADDEDIT_VENDOR: "/admin/addEditVendor",
  },

  VENDORS_LINK: {
    USERS: "/vendor/listUsers",
    ADDEDIT_USERS: "/vendor/registerUser",
  },

  ADMIN_BLOCKDELETEDATA: "/admin/blockDeleteData",

  ADMIN_LINK: {
    LISTDATA: "/admin/listData",
    ADDEDIT_ADMINS: "/admin/addEditAdmins",
  },

  DESTINATION_AND_QUOTA: {
    LISTING: "/vendor/listDesignations",
    ADDEDIT_DESTINATION_AND_QUOTA: "/vendor/addEditDesignation",
  },

  LEADS: {
    GET_LEADS: "Lead/GetAllLeads",
  },
  GENERATE_LEAD: "Lead/GenerateLead",
  USERS: {
    EDIT_USER: "admin/editUser",
    // CREATE_USER: "User/CreateUser",
    // UPDATE_USER: "User/UpdateUser",
  },
  ADVERTISEMENT_LIST: "advertisement/get",
  ADVERTISEMENT: {
    CREATE_ADVERTISEMENT: "advertisement/create",
    EDIT_ADVERTISEMENT: "advertisement/edit",
  },
  PLAYLIST_LIST: "playlist/get",
  PLAYLIST: {
    EDIT_PLAYLIST: "playlist/edit",
    CREATE_PLAYLIST: "playlist/create",
    ADD_PLAYLIST: "playlist/addSong",
  },
  SONG_CATEGORIES_LIST: "/songCategory/get",
  SONG_CATEGORIES: {
    CREATE_CATEGORIES: "/songCategory/create",
    EDIT_CATEGORIES: "/songCategory/edit",
  },
  SONG_LIST: "/song/get",
  SONG: {
    EDIT_SONG: "/song/edit",
    UPLOAD_SONG: "/song/upload",
    UPLOAD_BULK: "/song/uploadBulk",
  },
  ARTIST_LIST: "/artist/get",
  ARTISTS: {
    CREATE_ARTIST: "/artist/create",
    EDIT_ARTIST: "/artist/edit",
  },

  ADMIN: {
    CREATE_ADMIN: "/admin/create",
    DELETE_ADMIN: "/admin/delete",
    EDIT_ADMIN: "/admin/editAdmin",
  },
  EXPORT_LEAD: "/ExcelExporter/ExportLeadToExcel",
  DEALER_SUMMARY_REPORT: "Lead/GetSAPOrderDetailData",
  PROJECTION_SUMMARY_REPORT: "Lead/GetProjectionSummaryDetailData",
  STATE_PROJECTION_SUMMARY_EXPORT_LEAD:
    "/ExcelExporter/ExportProjectionSummaryDetailDataToExcel",

  ADMIN_LIST: "/admin/getAdmin",
  LIST_VENDORS: "/admin/listData",

  CREATE_USER: "User/CreateUser",
  COMMON_LOOK_UP: "Common/Lookup",
  USER_BY_ID: "User/GetUserById",
  UPDATE_USER: "User/UpdateUser",
  GET_VEHICLES_MODELS: "VehicleModel/GetVehicleModels",
  GET_VEHICLE_MODELS_BY_ID: "VehicleModel/GetVehicleModelById",
  UPDATE_VEHICLE_MODAL: "VehicleModel/UpdateVehicleModel",
  CREATE_VEHICLE_MODAL: "VehicleModel/CreateVehicleModel",
  CREATE_UPDATE_CUSTOMER_CATEGORY: "Common/CreateOrUpdateCustomerCategory",
  CREATE_UPDATE_FOLLOWUP_TYPE: "Common/CreateOrUpdateFollowUpType",
  CREATE_UPDATE_VISIT_PURPOSE: "Common/CreateOrUpdateVisitPurpose",
  ENQUIRY: {
    GET_ENQUIRY: "VehicleModel/GetEnquires",
    ADD_EDIT_ENQUIRY: "VehicleModel/CreateOrUpdateEnquire",
  },
  COMPETITORS: {
    GET_COMPETITORS: "VehicleModel/GetCompetitors",
    ADD_EDIT_COMPETITOR: "VehicleModel/CreateOrUpdateCompetitor",
  },
  STATES: {
    ADD_EDIT_STATE: "Common/CreateOrUpdateState",
  },
  DISTRICTS: {
    ADD_EDIT_DISTRICT: "Common/CreateOrUpdateDistrict",
  },
  DEALER: {
    GET_DEALER_STOCK: "/SmlApi/GetDealerStock",
  },
  DASHBOARD: {
    GET_DASHBOARD_REPORTS: "/Lead/GetPanelDashboardReports",
  },

  CHANGE_PASSWORD: "/crmapi/api/UserLogin/UserChangePassword",
};
