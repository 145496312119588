import { createSlice } from '@reduxjs/toolkit'
import { COLUMN_OPTIONS } from 'constants/app.constant'

export const initialState = {
    vendorlist:COLUMN_OPTIONS 
    
}

export const columnSlice = createSlice({
	name: 'column',
	initialState,
	reducers: {
        setstoredColumns: (state, action) => {
            state.vendorlist = action.payload
        }
	},
})

export const { setstoredColumns } = columnSlice.actions

export default columnSlice.reducer