import React from "react";
import TableSearchBar from "../TableSearchBar";
import { DatePicker, Button, Select } from "components/ui";
import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";
import { COLUMN_OPTIONS } from "constants/app.constant";
import CheckBoxDropdown from "../checkBoxDropDown";

const FilterBar = ({
  search,
  FilterMenu,
  setSearch,
  setFilterValue,
  filterValue,
  onFilterClick,
  motionFilters,
  handleBtnClick,
  isCreateBtn,
  btnLabel,
  placeholder,
  initialColumns,
  title,
}) => {
  return (
    <div className="flex sm:flex-row flex-col   gap-6 w-full items-center ">
      <div className="flex w-full gap-4  pb-2">
        {initialColumns && <CheckBoxDropdown columns={initialColumns} />}
        {FilterMenu?.isSearch && (
          <div className="flex sm:w-[280px]  w-full  ">
            <TableSearchBar
              search={search}
              placeholder={placeholder}
              onChange={(query) => setSearch(query)}
              SearchBarInfo={FilterMenu?.isSearch?.toolTipText}
            />
          </div>
        )}
        {/* {FilterMenu?.isCalendarFilter && (
          <div className="flex w-full 'sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <DatePicker.DatePickerRange
              size="sm"
              dateViewCount={2}
              value={[
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey1],
                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey2],
              ]}
              placeholder={FilterMenu?.isCalendarFilter?.placeholder}
              onChange={(dateArray) => {
                setFilterValue({
                  ...filterValue,
                  [FilterMenu?.isCalendarFilter?.filterKey1]: dateArray[0],
                  [FilterMenu?.isCalendarFilter?.filterKey2]: dateArray[1],
                });
              }}
            />
          </div>
        )} */}
      </div>
      <div className="flex w-full gap-3 md:justify-end ">
        {/* {FilterMenu.isColumnButton.label && (
          <label>{FilterMenu.isColumnButton.label}</label>
        )} */}
        {/* {columns.component === "select" && (
          <Select
            isMulti={true}
            autoComplete="off"
            size="sm"
            className={columns.className}
            placeholder={columns.placeholder}
            options={columns.options}
            value={""}
            onChange={(selectedValue) => {
              setSelectedColumns(selectedValue);
            }}
          />
        )} */}

        {FilterMenu?.isFilterButton && (
          <Button
            size="sm"
            className={`mr-2 ${motionFilters && "text-sky-500"}`}
            onClick={onFilterClick}
            icon={<CiFilter color={motionFilters && "sky"} />}
          >
            Filter
          </Button>
        )}
        {FilterMenu?.isResetButton && (
          <Button
            size="sm"
            onClick={() => {
              setFilterValue(FilterMenu?.isResetButton?.filterKey);
              setSearch("");
            }}
            icon={<GrPowerReset />}
          >
            Reset
          </Button>
        )}
        {btnLabel && (
          <Button variant="solid" size="sm" onClick={handleBtnClick}>
            {btnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
export default FilterBar;
